<template>
  <div>
    <div>
      <div class="_BoxHeader">
        <span>题库维护</span>
      </div>
      <div class="PageContain">
        <div class="_SelectHeader select-header">
          <span class="_Title">列表信息</span>
          <div>
            <el-button type="primary" @click="addQuestion">添加题库</el-button>
            <el-button
              class="_ImgBtn"
              @click="upExalloadFile"
              v-show="!isNotPurview"
              ><img src="../../assets/images/img1/import1.png" />导入</el-button
            >
            <input
              type="file"
              @change="importFile(this)"
              id="imFile"
              style="display: none"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <el-button class="_ImgBtn" @click="exportExcel"
              ><img src="../../assets/images/img1/import2.png" />导出</el-button
            >
            <el-button
              class="_ImgBtn"
              icon="el-icon-delete"
              @click="deleteItem"
            ></el-button>
          </div>
        </div>
        <div class="_Table">
          <span style="margin-left: 15px">公司：</span>
          <el-select
            v-model="Company"
            clearable
            placeholder="请选择"
            @change="togetArea"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 15px"> 部门：</span>
          <el-select
            v-model="Dept"
            clearable
            placeholder="请选择"
            @change="GetDeptQuestions"
          >
            <el-option
              v-for="item in optionDept"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 15px">文件名称：</span>
          <el-input
            style="width: 23%"
            clearable
            @clear="curIndex = []"
            placeholder="请输入文件名称"
            v-model="fileName2"
          />
          <span style="margin-left: 10px"></span>
          <el-button type="primary" @click="toSearch">搜索</el-button>
          <el-table
            :data="tableData3"
            style="width: 100%; margin-top: 20px"
            :height="elementHeight"
            :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
            border
            v-if="firstTable"
            ref="tableData3"
            :row-class-name="tableRowClassName"
            :row-style="selectedstyle"
            @row-click="rowClick"
            :cell-style="cellStyle"
            id="tableData3"
          >
            <el-table-column prop="compangyName" width="580" label="公司名称">
            </el-table-column>
            <el-table-column prop="fileCount" width="380" label="文件数量">
            </el-table-column>
            <el-table-column prop="questionCount" label="问题数量">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.questionCount }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            :data="tableData2"
            style="width: 100%; margin-top: 20px"
            :height="elementHeight"
            :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
            border
            v-else
            ref="tableData"
            :row-class-name="tableRowClassName"
            :row-style="selectedstyle"
            @row-click="rowClick"
            :cell-style="cellStyle"
            id="tableData3"
          >
            <el-table-column prop="fileName" label="文件名称" width="580">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
            <el-table-column
              prop="fileQuestionsCount"
              @click="dialogVisibleSearch = true"
              label="问题数量"
            >
              <template slot-scope="scope">
                <div @click="toOpen2(scope.row.fileNo)">
                  <span style="color: blue">{{
                    scope.row.fileQuestionsCount
                  }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="total">
            文件总数： <span>{{ fileTotal }}</span> 问题总数：
            <span>{{ total }}</span>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      title="添加题库"
      :showTowText="showBtnText"
      width="50%"
      top="25vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="contaion">
        <div>
          <div>
            <div class="_Inp1">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司：</span>
              <el-select
                v-model="dialogCompy"
                placeholder="请选择公司"
                @change="changDialogCompy"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp1">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部门：</span>
              <el-select
                v-model="dialogDept"
                placeholder="请选择部门"
                @change="changDialogDept"
              >
                <el-option
                  v-for="item in dialogDeptList"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp">
              <span>文件编号：</span>
              <el-input placeholder="请输入文件编号" v-model="fileNo">
              </el-input>
            </div>
            <div class="_Inp">
              <span>文件名称：</span>
              <el-input placeholder="请输入文件名称" v-model="fileName">
              </el-input>
            </div>
            <div class="_Inp">
              <span>问题描述：</span>
              <el-input placeholder="请输入问题描述" v-model="question">
              </el-input>
            </div>
            <div class="_Inp">
              <span>版本号：</span>
              <el-input placeholder="请输入版本号" v-model="version">
              </el-input>
            </div>
          </div>
          <div>
            <div class="_Inp1">
              <span>涉及范畴：</span>
              <el-select
                v-model="auditTypeName"
                collapse-tags
                multiple
                placeholder="请选择涉及范畴"
              >
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.auditTypeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp1">
              <span>涉及职能：</span>
              <el-select
                v-model="funName"
                collapse-tags
                multiple
                placeholder="请选择涉及职能"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.value"
                  :label="item.functionName"
                  :value="item.functionId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp">
              <span>标准答案：</span>
              <el-input placeholder="请输入标准答案" v-model="answer">
              </el-input>
            </div>
            <div class="_Inp">
              <span>法律法规要求：</span>
              <el-input placeholder="请输入法律法规要求" v-model="lawAsk">
              </el-input>
            </div>
            <div class="_Inp">
              <span>风险描述：</span>
              <el-input placeholder="请输入风险描述" v-model="question1">
              </el-input>
            </div>
            <div class="_Inp1">
              <span>涉及区域：</span>
              <el-select
                v-model="areaName"
                collapse-tags
                multiple
                placeholder="请选择涉及区域"
              >
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.areaName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div style="padding-left: 38px; padding-top: 15px">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :http-request="uploadFile"
            :headers="token"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </Dialog>
    <el-dialog
      title="题库统计"
      :visible.sync="dialogVisibleAsk"
      width="60%"
      top="5vh"
    >
      <span style="margin-left: 15px">公司：</span>
      <el-select
        v-model="Company"
        clearable
        placeholder="请选择"
        @change="togetArea"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.companyName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span style="margin-left: 15px"> 部门：</span>
      <el-select
        v-model="Dept"
        clearable
        placeholder="请选择"
        @change="GetDeptQuestions"
      >
        <el-option
          v-for="item in optionDept"
          :key="item.value"
          :label="item.deptmentName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span style="margin-left: 15px">文件名称：</span>
      <el-input
        style="width: 23%"
        clearable
        placeholder="请输入文件名称"
        v-model="fileName2"
      />
      <span style="margin-left: 10px"></span>
      <el-button type="primary" @click="toSearch">搜索</el-button>
      <el-table
        :data="tableData2"
        style="width: 100%; margin-top: 20px"
        height="400"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        border
        ref="tableData"
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        :cell-style="cellStyle"
        id="tableData2"
      >
        <el-table-column prop="fileName" label="文件名称" width="580">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="280">
        </el-table-column>
        <el-table-column
          prop="fileQuestionsCount"
          @click="dialogVisibleSearch = true"
          label="问题数量"
        >
          <template slot-scope="scope">
            <div @click="toOpen2(scope.row.fileNo)">
              <span>{{ scope.row.fileQuestionsCount }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">
        问题总数： <span>{{ total }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog @click="back()" fullscreen :visible.sync="dialogVisibleSearch">
      <div slot="title" class="header-title">
        <span class="title-name" @click="back"> 返回 题库 </span>
      </div>
      <div class="qustionBox">
        <el-button class="_ImgBtn" @click="exportExcel2()"
          ><img src="../../assets/images/img1/import2.png" />导出</el-button
        >
        <span>
          <img
            style="
              cursor: pointer;
              width: 30px;
              height: 30px;
              margin-left: 10px;
            "
            @click="delItem"
            src="../../assets/images/Examine_img/del.png"
            alt=""
          />
        </span>
      </div>
      <el-table
        :data="questionList2"
        :height="elementHeight + 100"
        style="width: 100%"
        :header-cell-style="{
          background: '#E8EBF4',
          color: '#5C5C5C',
          borderColor: '#C0C0C0',
        }"
        border
        @cell-dblclick="toChange"
        v-loading="loading"
        @select="selectRow"
        :key="undo"
        :cell-class-name="tableCellClassName"
        id="tabledData3"
      >
        <el-table-column type="selection" width="80" v-if="isUse">
        </el-table-column>
        <el-table-column prop="compName" label="公司" width="180">
          <template slot-scope="scope">
            <el-input
              v-if="
                curRow.questionId === scope.row.questionId &&
                columnID === scope.column.id
              "
              v-model="scope.row.compName"
            ></el-input>
            <span v-else>{{ scope.row.compName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="postName" label="岗位"> </el-table-column>
        <el-table-column prop="functionName" label="职能"> </el-table-column>
        <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column prop="question" label="检查内容"> </el-table-column>
        <el-table-column prop="auditMethod" width="180" label="审核方式">
        </el-table-column>
        <el-table-column prop="answer" width="380" label="标准要求">
        </el-table-column>
        <el-table-column prop="" width="180" label="判断题（答案是）">
        </el-table-column>
        <el-table-column prop="" width="180" label="判断题（答案否）">
        </el-table-column>
        <el-table-column prop="" width="180" label="选择题"> </el-table-column>
        <el-table-column prop="" width="180" label="选择题答案">
        </el-table-column>
        <el-table-column prop="" width="180" label="选择题正确答案">
        </el-table-column>
        <!-- <el-table-column prop="riskPoints" label="风险点"> </el-table-column>
        <el-table-column prop="riskMeasures" label="风险应对措施">
        </el-table-column>
        <el-table-column prop="version" label="版本号" width="40">
        </el-table-column>
        <el-table-column prop="" align="center" label="检查类别">
          <el-table-column prop="" label="1">
            <el-table-column prop="areaAudit" label="现场检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.areaAudit ? '√' : '' }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="fileAudit" label="文件检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fileAudit ? '√' : '' }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="isCurrency" label="通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isCurrency ? '√' : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isFuncCurrency" label="职能通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isFuncCurrency ? '√' : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isSpecial" label="专指">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isSpecial ? '√' : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="" align="center" label="所在区域">
          <el-table-column prop="arrArea" label="1">
            <el-table-column label="厂区区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('厂区区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="arrArea" label="车间区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('车间区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="仓库区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('仓库区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="实验室区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('实验室区域')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="办公区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('办公区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="饭堂区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('饭堂区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="宿舍区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('宿舍区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="工程区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('工程区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="center" label="检查范畴">
          <el-table-column prop="" label="1">
            <el-table-column prop="" label="安全生产">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.arrAuditType.split(',').includes('安全生产')
                        ? '√'
                        : ''
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="" label="领导干部现场带班">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('领导干部现场带班')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="三违行为管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('三违行为管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="班组岗位达标安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('班组岗位达标安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="安全教育作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('安全教育作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="交叉作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('交叉作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="相关方施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('相关方施工安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="特种作业人员管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('特种作业人员管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="9">
            <el-table-column prop="" label="特种设备管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('特种设备管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="10">
            <el-table-column prop="" label="危险作业工作票作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('危险作业工作票作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="11">
            <el-table-column prop="" label="动火作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('动火作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="12">
            <el-table-column prop="" label="高空作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('高空作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="13">
            <el-table-column prop="" label="吊装作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('吊装作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="14">
            <el-table-column prop="" label="有限空间作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('有限空间作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="15">
            <el-table-column prop="" label="搬运安全作业"
              ><template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('搬运安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="16">
            <el-table-column prop="" label="电焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('电焊机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="17">
            <el-table-column prop="" label="气焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('气焊机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="18">
            <el-table-column prop="" label="砂轮机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('砂轮机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="19">
            <el-table-column prop="" label="手持电动工具安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('手持电动工具安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="20">
            <el-table-column prop="" label="起重机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('起重机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="21">
            <el-table-column prop="" label="液氨泄漏探测仪检测作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('液氨泄漏探测仪检测作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="22">
            <el-table-column prop="" label="液氨使用作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('液氨使用作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="23">
            <el-table-column prop="" label="涉水作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('涉水作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="24">
            <el-table-column prop="" label="危险化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('危险化学品')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="25">
            <el-table-column prop="" label="一般化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('一般化学品')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="26">
            <el-table-column prop="" label="安全用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('安全用电')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="27">
            <el-table-column prop="" label="临时用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('临时用电')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="28">
            <el-table-column prop="" label="职业健康安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('职业健康安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="29">
            <el-table-column prop="" label="劳动防护">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('劳动防护')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="30">
            <el-table-column prop="" label="施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('施工安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="31">
            <el-table-column prop="" label="消防设施">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('消防设施')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="32">
            <el-table-column prop="" label="消防设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('消防设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="33">
            <el-table-column prop="" label="疏散指示">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('疏散指示')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="34">
            <el-table-column prop="" label="特种设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('特种设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="35">
            <el-table-column prop="" label="机械设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('机械设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="36">
            <el-table-column prop="" label="投加设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('投加设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="37">
            <el-table-column prop="" label="高低压电柜">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('高低压电柜')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="38">
            <el-table-column prop="" label="一般设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('一般设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="39">
            <el-table-column prop="" label="作业环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('作业环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="40">
            <el-table-column prop="" label="办公环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('办公环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="41">
            <el-table-column prop="" label="实验室环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('实验室环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="42">
            <el-table-column prop="" label="车间环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('车间环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="43">
            <el-table-column prop="" label="仓库环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('仓库环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="44">
            <el-table-column prop="" label="宿舍环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('宿舍环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="45">
            <el-table-column prop="" label="应急物资">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('应急物资')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="46">
            <el-table-column prop="" label="应急设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('应急设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="47">
            <el-table-column prop="" label="应急预案">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('应急预案')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="48">
            <el-table-column prop="" label="应急演练">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('应急演练')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="49">
            <el-table-column prop="" label="7S管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('7S管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="50">
            <el-table-column prop="" label="设备状态">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('设备状态')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="51">
            <el-table-column prop="" label="安全警示标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('安全警示标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="52">
            <el-table-column prop="" label="作业指导书标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('作业指导书标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="53">
            <el-table-column prop="" label="行为标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('行为标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="54">
            <el-table-column prop="" label="信息栏">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('信息栏')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="55">
            <el-table-column prop="" label="卫生用品存放">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('卫生用品存放')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="56">
            <el-table-column prop="" label="四色图">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('四色图')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="57">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('疫情防控')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="58">
            <el-table-column prop="" label="管道安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('管道安装工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="59">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('疫情防控')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="60">
            <el-table-column prop="" label="构筑物工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('构筑物工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="61">
            <el-table-column prop="" label="道路工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(',').includes('道路工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="62">
            <el-table-column prop="" label="设备安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('设备安装工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="63">
            <el-table-column prop="" label="机电安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('机电安装工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="64">
            <el-table-column prop="" label="水环境治理工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(',')
                      .includes('水环境治理工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column> -->
        <!-- </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSearch = false">取 消</el-button>
        <el-button type="primary" @click="toSure">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="500px">
      <div style="text-align: center; margin: 20px 0">
        <el-checkbox v-model="deleExam">同时删除考试文件</el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, baseURL } from '../../api/http'
import Dialog from '../../components/dialog/Dialog'
import XLSX from 'xlsx'
// import FileSaver from 'file-saver'
import { exportExcel } from '../../modules/ExportExcel'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'

export default {
  components: { Dialog },
  data() {
    return {
      dialogVisible: false,
      title: '',
      isNotPurview: false,
      BUrl: baseURL[process.env.NODE_ENV + ''],
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      isUse: true,
      elementHeight: 0,
      showDialog: false,
      fileNameInp: '',
      imageUrl: [],
      tableData2: [],
      tableData3: [],
      showBtnText: false,
      rowItem: {},
      options: [],
      Company: '',
      delQuestion: '',
      dialogVisibleAsk: false,
      tableRowIndex: null,
      importDataList: [],
      question1: '',
      dialogDeptList: [],
      reqImageList: [],
      // 搜索
      fileNoInp: '',
      questionList: [],
      isSearch: false,
      // 参数
      dialogCompy: '',
      dialogDept: '',
      fileNo: '',
      fileName: '',
      question: '',
      answer: '',
      lawAsk: '',
      version: '',
      // 分页
      currentPage: 1,
      pageSize: 20,
      listCount: 0,
      //
      auditTypeName: '',
      areaName: '',
      funName: '',
      option1: [],
      option2: [],
      option3: [],
      //问题统计
      total: 0,
      fileName2: '',
      optionDept: '',
      Dept: '',
      loading: false,
      curIndex: [],
      dialogVisibleSearch: false,
      questionList2: null,
      fileTotal: 0,
      //
      postList: [],
      True: true,
      firstTable: true,
      highlightData: [],
      curRow: {},
      columnID: '',
      deleExam: false,
    }
  },
  mounted() {
    this.getdate()
    this.imFile = document.getElementById('imFile')
    this.getElementHeight()
    this.getHighlightData()
    // 普通角色 不能导入题库，其它权限正常
    var roleName = JSON.parse(
      sessionStorage.getItem('userInfo')
    ).roleName.split(',')
    if (
      roleName.indexOf('股份公司管理员') == -1 &&
      roleName.indexOf('公司管理员') == -1 &&
      roleName.indexOf('部门管理员') == -1
    ) {
      this.isNotPurview = true
    }
    get('/api/Company/GetAllByUser')
      .then((res) => {
        this.options = res.data
      })
      .then(() => {
        this.Company = JSON.parse(
          sessionStorage.getItem('userInfo')
        ).upCompanyId
        get('/api/Department/GetNoTree?CompId=' + this.Company).then((res) => {
          this.optionDept = res.data
        })
      })
    get('/api/QuestionAndCompangy').then((res) => {
      if (res.data.length != 0) {
        res.data.map((item) => {
          this.total += item.questionCount
          this.fileTotal += item.fileCount
        })
      }
      this.tableData3 = res.data
    })
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('tableData3').offsetTop + 60 + 102)
      })
    },
    rowClick(row) {
      this.rowItem = row
      this.tableRowIndex = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    fuzzyQuery(list, keyWord) {
      var arr = []
      for (var i = 0; i < list.length; i++) {
        if (list[i].fileName.indexOf(keyWord) >= 0) {
          arr.push(list[i].index)
        }
      }
      return arr
    },
    toSearch() {
      get(
        '/api/QuestionPool/GetQuestionByFile?CompId=' +
          this.Company +
          '&DeptId=' +
          this.Dept +
          '&FileNo=' +
          '&FileName=' +
          this.fileName2
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.total = 0
            resp.data.map((item) => {
              this.total += item.fileQuestionsCount
              if (this.Company) {
                this.highlightData.map((i) => {
                  if (i.fileNo == item.fileNo) {
                    item.showColor = true
                  }
                })
              }
            })
            this.tableData2 = resp.data
            this.firstTable = false
            this.tableRowIndex = null
            this.fileTotal = this.tableData2.length
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('搜索失败')
        })
    },
    getdate() {
      get('/api/AuditType/GetList').then((res) => {
        this.option1 = res.data
      })
      get('/api/Area/GetList').then((res) => {
        this.option2 = res.data
      })
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    cellStyle(e) {
      if (this.curIndex.includes(e.row.index)) {
        return 'background: #409eff; color:#ffffff'
      }
      if (e.row.showColor && e.columnIndex == 1) {
        return 'color:red'
      }
    },
    handleClose() {
      this.showDialog = false
      this.imageUrl = []
    },
    // 上传图片
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile, e.file.uid)
      }
    },
    handleRemove(file) {
      this.reqImageList.map((i, index) => {
        if (file.raw.uid == i.uid) {
          this.reqImageList.splice(index, 1)
        }
      })
    },
    uploadFileReq(file, uid) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          if (res.code == 200) {
            this.reqImageList.push({ uid: uid, value: res.value })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
    },
    // 增删改
    handleReqData() {
      var imageAddr = []
      if (this.reqImageList.length != 0) {
        this.reqImageList.map((i) => {
          imageAddr.push(i.value)
        })
      }
      var data = {
        id: 0,
        compId: this.dialogCompy,
        deptId: this.dialogDept,
        fileNo: this.fileNo,
        fileName: this.fileName,
        question: this.question,
        answer: this.answer,
        imageAddr: imageAddr.toString(),
        lawAsk: this.lawAsk,
        version: this.version,
        isDelete: 0,
        questionAndArea: this.areaName.toString(),
        questionAndFunction: this.funName.toString(),
        questionAndAuditType: this.auditTypeName.toString(),
        saveTime: new Date().toISOString(),
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      post('/api/NewQuestionPool', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '添加成功',
            })
            this.showDialog = false
            this.isSearch = false
            this.reqImageList = []
            this.$refs.upload.clearFiles()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('添加失败')
        })
    },
    toOpen() {
      this.dialogVisibleAsk = true
      get('/api/Company/GetAll').then((res) => {
        this.options = res.data
      })
    },
    back() {
      this.dialogVisibleSearch = false
    },
    toOpen2(v) {
      if (!this.Company) {
        this.$message({
          type: 'warning',
          message: '请选择公司部门',
        })
        return
      }
      this.questionList2 = []
      get(
        '/api/QuestionPoolUpt/QuestionPoolExport?CompId=' +
          this.Company +
          '&DeptId=' +
          this.Dept +
          '&FileNo=' +
          v
      ).then((res) => {
        res.data.forEach((row) => {
          if (row.postName === '') {
            row.postName = '空'
          }
        })
        this.questionList2 = res.data

        this.dialogVisibleSearch = true
      })
    },

    GetDeptQuestions() {
      if (!this.Dept) return
      get(
        '/api/QuestionPool/GetDeptFileQuestions?CompId=' +
          this.Company +
          '&DeptId=' +
          this.Dept
      ).then((res) => {
        this.total = 0
        this.fileTotal = 0
        if (res.data.length != 0) {
          res.data.map((item) => {
            this.total += item.fileQuestionsCount
            if (this.Company) {
              this.highlightData.map((i) => {
                if (i.fileNo == item.fileNo) {
                  item.showColor = true
                }
              })
            }
          })
        }
        this.fileTotal = res.data.length
        this.curIndex = []
        this.tableData2 = res.data
        this.firstTable = false
        this.tableRowIndex = null
      })
    },
    getHighlightData() {
      get('/api/BaseFileList/GetNotUpt').then((resp) => {
        if (resp.code == 200) {
          this.highlightData = resp.data
        }
      })
    },
    togetArea(e) {
      if (!e) return
      this.curIndex = []
      this.Dept = ''
      this.optionDept = []
      this.fileName2 = ''
      this.getDeptList(e)
      get('/api/QuestionPool/GetFileQuestions?CompId=' + e).then((res) => {
        this.total = 0
        if (res.data.length != 0) {
          res.data.map((item) => {
            this.total += item.fileQuestionsCount
            this.highlightData.map((i) => {
              if (i.fileNo == item.fileNo) {
                item.showColor = true
              }
            })
          })
        }
        this.fileTotal = res.data.length
        this.firstTable = false
        this.tableData2 = res.data
      })
    },
    getDeptList(e) {
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        if (this.showDialog) {
          this.dialogDeptList = res.data
          return
        }
        this.optionDept = res.data
      })
    },
    changDialogCompy(e) {
      this.getDeptList(e)
    },
    changDialogDept(e) {
      this.option3 = []
      get(
        '/api/PostAndFile/GetFunction?CompId=' +
          this.dialogCompy +
          '&DeptId=' +
          e
      ).then((res) => {
        this.option3 = res.data
      })
    },
    addQuestion() {
      this.dialogCompy = ''
      this.dialogDeptList = []
      this.dialogDept = ''
      this.option3 = []
      this.showDialog = true
      this.showBtnText = false
      this.fileNo = ''
      this.fileName = ''
      this.question = ''
      this.answer = ''
      this.lawAsk = ''
      this.version = ''
      this.areaName = ''
      this.funName = ''
      this.auditTypeName = ''
    },
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    // toChange(item, column) {
    //   if (column.index >= 12 && column.index <= 16) {
    //     var n = column.property
    //     item[n] === 1 ? (item[n] = 0) : (item[n] = 1)
    //   }
    //   if (column.index >= 17 && column.index <= 24) {
    //     if (item.arrArea.split(',').indexOf(column.label) >= 0) {
    //       var Nitem = item.arrArea.split(',')
    //       var index = item.arrArea.split(',').indexOf(column.label)
    //       Nitem.splice(index, 1)
    //       item.arrArea = Nitem.toString()
    //     } else {
    //       item.arrArea === ''
    //         ? (item.arrArea = column.label)
    //         : (item.arrArea = item.arrArea + ',' + column.label)
    //     }
    //   }
    //   if (column.index > 24) {
    //     if (item.arrAuditType.split(',').indexOf(column.label) >= 0) {
    //       var Nitem2 = item.arrAuditType.split(',')
    //       var index2 = item.arrAuditType.split(',').indexOf(column.label)
    //       Nitem2.splice(index2, 1)
    //       item.arrAuditType = Nitem2.toString()
    //     } else {
    //       item.arrAuditType === ''
    //         ? (item.arrAuditType = column.label)
    //         : (item.arrAuditType = item.arrAuditType + ',' + column.label)
    //     }
    //   }
    //   JSON.stringify(this.postList).indexOf(JSON.stringify(item)) >= 0
    //     ? ''
    //     : this.postList.push(item)
    // },
    // 双击单元格
    toChange(row, column) {
      this.curRow = row
      this.columnID = column.id
    },
    toSure() {
      this.loading = true
      post('/api/QuestionPoolUpt/BatchUpt', {
        data: JSON.stringify(this.postList),
      }).then((res) => {
        this.loading = false
        this.postList = []
        this.$message({
          type: 'success',
          message: res.message,
        })
      })
    },
    changeRow(row) {
      post('/api/QuestionPoolUpt/BatchUpt', {
        data: JSON.stringify([row]),
      }).then(() => {
        this.loading = false
      })
    },
    selectRow(e) {
      this.delQuestion = e
    },
    deleteItem() {
      if (this.firstTable) {
        this.$message({
          type: 'warning',
          message: '不可删除',
        })
        return
      }
      if (this.tableRowIndex == null) return
      this.dialogVisible = true
      this.deleExam = false
      this.title = '您确认要删除“' + this.rowItem.fileName + '”的这个文件？'

      // this.$confirm('您确认要删除“' + this.rowItem.fileName + '”的这个文件？')
      //   .then(() => {
      //     deleteMethod('/api/NewQuestionPool/DeleteByFile', {
      //       FileNo: this.rowItem.fileNo,
      //       FileName: this.rowItem.fileName,
      //       deleExam: this.deleExam
      //     })
      //       .then((resp) => {
      //         if (resp.code == 200) {
      //           this.$message({
      //             type: 'success',
      //             message: '删除成功',
      //           })
      //           this.tableData2.splice(this.tableRowIndex, 1)
      //           this.tableRowIndex = null
      //           this.rowItem = {}
      //         } else {
      //           this.$message.error(resp.message)
      //         }
      //       })
      //       .catch(() => {
      //         this.$message.error('删除失败')
      //       })
      //   })
      //   .catch(() => {})
    },
    handleDelete() {
      deleteMethod('/api/NewQuestionPool/DeleteByFile', {
        FileNo: this.rowItem.fileNo,
        FileName: this.rowItem.fileName,
        deleExam: this.deleExam,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功',
            })
            this.tableData2.splice(this.tableRowIndex, 1)
            this.tableRowIndex = null
            this.rowItem = {}
            this.dialogVisible = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('删除失败')
        })
    },
    // 导入excel
    upExalloadFile: function () {
      // 点击导入按钮
      this.imFile.click()
    },
    importFile: function () {
      var importDataList = []

      let obj = this.imFile
      if (!obj.files) {
        return
      }
      var f = obj.files[0]
      var reader = new FileReader()
      let $this = this
      reader.onload = function (e) {
        var data = e.target.result
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: 'base64',
          })
        } else {
          $this.wb = XLSX.read(data, {
            type: 'binary',
          })
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        )
        excelData.map((item) => {
          importDataList.push({
            id: 0,
            fileName: item.文件名称,
            fileNo: item.文件编号,
            answer: item.标准答案,
            lawAsk: item.法律法规要求,
            question: item.问题描述,
            imageAddr: '',
            isDelete: 0,
            saveTime: new Date().toISOString(),
            saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          })
        })
        if (importDataList.length != 0) {
          $this.importQuestion(importDataList)
        } else {
          $this.$message({
            type: 'warning',
            message: '导入文件不符合',
          })
        }
      }
      if (this.rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },
    delItem() {
      this.loading = true
      var idList = []
      this.delQuestion.forEach((element) => {
        idList.push({ QuestionId: element.questionId })
      })
      post('/api/QuestionPool/BatchDelete', {
        data: JSON.stringify(idList),
      })
        .then((res) => {
          this.loading = false
          this.$message({
            type: 'success',
            message: res.message,
          })
        })
        .then(() => {
          this.delQuestion.forEach((element) => {
            this.questionList2.forEach((i, index) => {
              if (i.questionId == element.questionId) {
                this.questionList2.splice(index, 1)
              }
            })
          })
        })
    },
    importQuestion(list) {
      post('/api/QuestionPool/BatchAdd', {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '导入成功',
            })
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('导入失败')
        })
    },
    getCellWidth(value) {
      // 判断是否为null或undefined
      if (value == null) {
        return 10
      } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
        const chineseLength = value.match(/[\u4e00-\u9fa5]/g).length
        const otherLength = value.length - chineseLength
        return chineseLength * 2.1 + otherLength * 1.1
      } else {
        return value.toString().length * 1.1
      }
    },
    // 导出
    exportExcel() {
      return exportExcel('#tableData3', '题库维护.xlsx')
    },
    exportExcel2() {
      this.isUse = false
      this.$nextTick(() => {
        if (!this.isUse) {
          exportExcel('#tabledData3', '题库维护.xlsx')
          this.isUse = true
        }
      })
      // this.isUse = false
      // this.$nextTick(() => {
      //   var wb = XLSX.utils.table_to_book(
      //     document.querySelector('#tabledData3')
      //   )
      //   let ray = []
      //   let item = wb['Sheets']['Sheet1']
      //   for (const key in item) {
      //     if (item[key].t) {
      //       if (item[key].t == 's' && item[key].v !== '√') {
      //         let obj = {
      //           name: key.slice(0, 1),
      //           items: item[key],
      //         }
      //         ray.push(obj)
      //       }
      //     }
      //   }
      //   let rayZ = ray.splice(91, ray.length)
      //   let widthList = [
      //     { name: 'A', length: 0 },
      //     { name: 'B', length: 0 },
      //     { name: 'C', length: 0 },
      //     { name: 'D', length: 0 },
      //     { name: 'E', length: 0 },
      //     { name: 'F', length: 0 },
      //     { name: 'G', length: 0 },
      //     { name: 'H', length: 0 },
      //   ]
      //   for (let index = 0; index < rayZ.length; index++) {
      //     widthList.forEach((i) => {
      //       if (i.name === rayZ[index].name) {
      //         i.length =
      //           rayZ[index].items.v.length >= i.length
      //             ? rayZ[index].items.v.length
      //             : i.length
      //       }
      //     })
      //   }
      //   widthList.forEach((e) => {
      //     wb['Sheets']['Sheet1']['!cols'].push({
      //       wpx: e.length * 15,
      //     })
      //   })
      //   var wbout = XLSX.write(wb, {
      //     bookType: 'xlsx',
      //     bookSST: true,
      //     type: 'array',
      //   })
      //   try {
      //     FileSaver.saveAs(
      //       new Blob([wbout], { type: 'application/octet-stream' }),
      //       '题库维护.xlsx'
      //     )
      //   } catch (e) {
      //     if (typeof console !== 'undefined') console.log(e, wbout)
      //   }
      //   this.isUse = true
      //   return wbout
      // })
    },
  },
}
</script>

<style lang="less" scoped>
.dialog ._Select {
  width: 100% !important;
}
.dialog .el-select {
  width: 100%;
  margin-top: 10px;
}
.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}
.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}
.dialog ._Select > span,
.dialog ._Inp > span {
  width: 170px;
}
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e7e8;
  padding-top: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
/* 上传图片 */
/deep/ .el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}
/deep/ .el-dialog__body {
  padding: 30px 20px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
._Inp1 .el-select {
  width: 75%;
}
._Inp .el-select {
  margin-top: 0;
  height: auto !important;
  display: flex !important;
}
._Inp .el-select > span {
  width: auto !important;
}
._Inp1 {
  margin-left: 11.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
._Inp1 input {
  display: flex;
  height: auto;
}
._Inp1 span {
  white-space: nowrap;
}
._Table {
  overflow: auto;
}
.header-title {
  display: flex;
  align-items: center;
  color: white;
}
.qustionBox {
  display: flex;
  margin-bottom: 5px;
}
.total {
  position: absolute;
  right: 20px;
  padding-right: 20px;
  padding-top: 20px;
  font-weight: bold;
  cursor: pointer;
  > span {
    color: red;
    font-size: 13px;
  }
}
.activefileNo {
  color: red !important;
}
</style>
